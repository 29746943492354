<template>
  <div class="content-wrapper" id="campanha-nacional-registro-cadastro">
    <PageHeader screenName="Editar Campanha" :linkItems="linkItems" />

    <b-overlay
      variant="white"
      :show="showLoading"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <InfosCampanhaForm :vacinas="vacinasData" v-if="!showLoading" />

      <section v-if="!showLoading">
        <GroupPricingDoseDistribuition @habilitou="habilitou" />
        <BottomButtons @habilitou="habilitou" :idCampanha="idCampanha" :edit="true" />
      </section>
    </b-overlay>
  </div>
</template>
  
<script>
import { BOverlay } from "bootstrap-vue";
import PageHeader from "@/views/components/custom/page-header/PageHeader.vue";
import GroupPricingAndDoseDistribution from "./FormGroups/GroupPricingAndDoseDistribution.vue";
import DepartamentoModal from "@/views/components/custom/modals/ModalErrorDepartamento.vue";
import SucessoModal from "@/views/components/custom/modals/SucessoCriacaoEdicaoModal.vue";
import ModalShowError from "@/views/components/custom/modals/ModalShowError.vue";
import BottomButtons from "@/views/components/custom/ButtonsCampanhaNacional/BottomButtons.vue";
import InfosCampanhaForm from "@/views/custom-pages/campanha-nacional/Edit/Forms/InfosCampanhaForm.vue";
import { getIdCampaignEditAndShow } from "@core/utils/store/getStore";
import { setIdCampaignEditAndShow } from "@core/utils/store/setStore";
import {
  CampanhaNacionalSetInit,
  CampanhaNacionalSetFormDoencas,
  CampanhaNacionalSetFormGrupos,
  CampanhaNacionalSetQtdAdesoes,
  CampanhaNacionalSetDescricao,
  CampanhaNacionalSetFormVacinas,
  CampanhaNacionalSetVacinasSelecionada,
  CampanhaNacionalSetDataInicioSelecionado,
  CampanhaNacionalSetDataTerminoSelecionado,
  CampanhaNacionalSetDoencaSelecionada,
  CampanhaNacionalAtualizaPrecificacaoEmModoEdicao,
} from "@/views/custom-pages/campanha-nacional/Helpers/campanhaNacionalHelpers.js";

import GroupPricingDoseDistribuition from "./FormGroups/GroupPricingDoseDistribuition.vue";

export default {
  title: "Editar Campanha",

  components: {
    BOverlay,
    PageHeader,
    GroupPricingAndDoseDistribution,
    SucessoModal,
    DepartamentoModal,
    BottomButtons,
    ModalShowError,
    InfosCampanhaForm,
    GroupPricingDoseDistribuition,
  },

  data() {
    return {
      linkItems: [
        {
          name: "Campanhas",
          routeName: "campanha-list-nacional",
        },
        {
          name: "Editar Campanha",
          active: true,
        },
      ],
      showLoading: true,
      updatedAt: null,
      payload: {},
      vacinasData: [],
      idCampanha: null,
      response: null,
      preficicado: false,
    };
  },

  async mounted() {
    await CampanhaNacionalSetFormDoencas();
    await CampanhaNacionalSetFormGrupos();

    const hasIdEntityRouteParam = this.$helpers.verifyIfHasIdEntityRouteParam(
      getIdCampaignEditAndShow(),
      this.$route.params.idCampanha
    );
    if (hasIdEntityRouteParam) {
      setIdCampaignEditAndShow(this.$route.params.idCampanha);
    }
    this.idCampanha = getIdCampaignEditAndShow();
    this.loadDataCampaign(getIdCampaignEditAndShow());
  },
  destroyed() {
    CampanhaNacionalSetInit({
      idUsuarioLogado: null,
    });
  },
  methods: {
    loadDataCampaign(idCampanha) {
      this.$http
        .get(this.$api.campanhaNacionalId(idCampanha))
        .then(({ data }) => {
          this.handleDataCampaign(data);
          this.response = data;
          this.showLoading = false;
        });
    },
    handleDataCampaign(data) {
      CampanhaNacionalSetDescricao(data.descricao);
      CampanhaNacionalSetDoencaSelecionada(data.id_doenca);
      CampanhaNacionalSetFormVacinas(data.id_doenca);
      CampanhaNacionalSetQtdAdesoes(data.qtd_adesoes);
      let vacinas = [];

      data.campanha_vacina.map((item) => {
        this.vacinasData.push({
          id_vacina: item.id_vacina,
          nome_tecnico: item.nome_tecnico,
        });
        const vacina = {
          id_vacina: item.id_vacina,
          nome_tecnico: item.nome_tecnico,
          grupos: item.estimativa,
        };

        vacinas.push(vacina);
        CampanhaNacionalSetVacinasSelecionada(vacinas);
      });

      CampanhaNacionalSetDataInicioSelecionado(data.inicio);
      CampanhaNacionalSetDataTerminoSelecionado(data.termino);
    },
    habilitou() {
      CampanhaNacionalAtualizaPrecificacaoEmModoEdicao(this.response);
    },
  },
  watch: {
    "$store.state.campanhaNacionalState.watchers.precificacao": {
      immediate: false,
      handler() {
        if (!this.precificado) {
          this.precificado = true;
        }
      },
    },
  },
};
</script>
<style lang="scss">
@import "@/views/custom-pages/campanha-nacional/campanhaNacional.scss";
</style>