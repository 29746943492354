import { render, staticRenderFns } from "./PrecificationBaseForm.vue?vue&type=template&id=35e47cb7&scoped=true&"
import script from "./PrecificationBaseForm.vue?vue&type=script&lang=js&"
export * from "./PrecificationBaseForm.vue?vue&type=script&lang=js&"
import style0 from "./PrecificationBaseForm.vue?vue&type=style&index=0&id=35e47cb7&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35e47cb7",
  null
  
)

export default component.exports