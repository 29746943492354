<template>
  <b-alert 
        :variant="variant" 
        :show="show" 
        v-height-fade.appear
    >
        <div class="alert-body">
            <strong :class="'text-'+variant">{{ message }}</strong>
        </div>
    </b-alert>
</template>

<script>
import { BAlert } from 'bootstrap-vue'
import { heightFade } from '@core/directives/animations'

export default {
    components: {
        BAlert
    }, 

    directives: {
        heightFade
    },

    props: {
        message: {
            required: true,
            type: String,
        },
        show: {
            required: true,
            type: Boolean,
        },
        variant: {
            type: String
        }
    },
}
</script>

