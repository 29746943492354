
import PrecificationFormService from '../Services/vaccinePricingFormService';

export default {
    makeTiposEmpresaCategoriasPrecification(vaccinePriccingForm, oldPrices, edit = false) {
        vaccinePriccingForm.tiposEmpresaCategorias.map((tipoEmpresa, tipoIndex) => {
            let valueWithPromotion = null;
            let valueNoPromotion = null;

            if(oldPrices[tipoIndex]){
                valueWithPromotion = oldPrices[tipoIndex].priceWithPromotion;
                valueNoPromotion = oldPrices[tipoIndex].priceNoPromotion;
            }

            const categoryPrices = PrecificationFormService.fillValuesFormVaccinePricing(
                vaccinePriccingForm, tipoEmpresa, valueNoPromotion, valueWithPromotion
            );

            vaccinePriccingForm.formPrecification.push({
                companyType: tipoEmpresa.descricao,
                idTipoEmpresa: tipoEmpresa.id_tipo_empresa,
                activeCompanyType: false,
                complete: edit,
                priceNoPromotion: categoryPrices.categoryNoPromotion,
                priceWithPromotion: categoryPrices.categoryWithPromotion
            });
        });
    },

    cleanFormCategory(companyType, formPrecification) {
        const categoryPrices = formPrecification.find(prices => prices.companyType == companyType);

        categoryPrices.priceNoPromotion.map(price => price.value = '0,00');
        categoryPrices.priceWithPromotion.map(price => price.value = '0,00');
    },

    activeCompanyType(companyType, formPrecification) { 
        const categoryPrices = formPrecification.find(prices => prices.companyType == companyType);

        const noPromorionAplicable = categoryPrices.priceNoPromotion.find(noPromotion => !noPromotion.notApplicable);
        const promorionAplicable = categoryPrices.priceWithPromotion.find(promotion => !promotion.notApplicable);

        if(noPromorionAplicable || promorionAplicable) {
            categoryPrices.activeCompanyType = false;
            return;
        }

        categoryPrices.activeCompanyType = true;
    },

    changeStateCategory(companyType, activeCompanyType, vaccinePriccingForm) {
        const categoryPrices = vaccinePriccingForm.formPrecification.find(prices => prices.companyType == companyType);

        if(vaccinePriccingForm.hasDosesNoPromotion) {
            categoryPrices.priceNoPromotion.map(price => price.notApplicable = !activeCompanyType);
        }
        if(vaccinePriccingForm.hasIncentivePromotion && vaccinePriccingForm.hasDosesPromotion) {
            categoryPrices.priceWithPromotion.map(price => price.notApplicable = !activeCompanyType);
        }

        categoryPrices.activeCompanyType = !activeCompanyType;
    },

    checkIfPrecificationIsComplete(index, formPrecification) {
        let isComplete = true;
        let completeItens = [];

        const form = formPrecification[index];

        const categoryAplicableNoPromotion = form.priceNoPromotion.filter(noPromotionPrice => !noPromotionPrice.notApplicable);
        const categoryAplicablePromotion = form.priceWithPromotion.filter(promotionPrice => !promotionPrice.notApplicable);

        completeItens.push(...PrecificationFormService.checkPriceValueIsZero(categoryAplicableNoPromotion));
        completeItens.push(...PrecificationFormService.checkPriceValueIsZero(categoryAplicablePromotion));

        if(completeItens.includes(false)) { isComplete = false; }
        
        formPrecification[index].complete = isComplete;
    },

    checkIfCanSavePrecification(vaccinePriccingForm, busEvent) {
        let errorPrecification = false;

        busEvent.$emit('checkHasDistributionRegion', vaccinePriccingForm.selectedVaccine);

        const precificationFilterInactive = vaccinePriccingForm.formPrecification.filter(precification => !precification.activeCompanyType);

        if(precificationFilterInactive.length == 0) {
            busEvent.$emit('canSaveSchedulePrice', [true, vaccinePriccingForm.selectedVaccine]);
            vaccinePriccingForm.allIsDeactivated = true;
            return true;
        }

        errorPrecification = PrecificationFormService.checkIfHasErrorPrices(precificationFilterInactive, vaccinePriccingForm);

        if(!errorPrecification) { vaccinePriccingForm.allIsDeactivated = false; }

        vaccinePriccingForm.$emit('errorValidationPrecification', errorPrecification);
        busEvent.$emit('canSaveSchedulePrice', [!errorPrecification, vaccinePriccingForm.selectedVaccine]);
        busEvent.$emit('disableButtonSave', { error: errorPrecification});
        return errorPrecification;
    },

    preparePrecificationToSave(formPrecification, selectedIncentives) {
        const precosToSave = [];

        const tiposPrecosActives = formPrecification.filter(precification => !precification.activeCompanyType);

        const promotion = selectedIncentives.filter(inccentive => inccentive.nome !== 'Normal');
        const noPromotion = selectedIncentives.filter(inccentive => inccentive.nome == 'Normal');

        tiposPrecosActives.map(companyTypes => {
            const categoryAplicableNoPromotion = companyTypes.priceNoPromotion.filter(noPromotionPrice => !noPromotionPrice.notApplicable);
            const categoryAplicablePromotion = companyTypes.priceWithPromotion.filter(promotionPrice => !promotionPrice.notApplicable);
                
            PrecificationFormService.fillPricesToSave(
                categoryAplicableNoPromotion, 
                noPromotion.length ? noPromotion[0].id_incentivo : 1, 
                companyTypes.idTipoEmpresa, 
                precosToSave
            );
            PrecificationFormService.fillPricesToSave(
                categoryAplicablePromotion, 
                promotion.length ? promotion[0].id_incentivo : 2,
                companyTypes.idTipoEmpresa, 
                precosToSave
            );
        });

        return precosToSave;
    },

    checkPricesIsFilled(precificationFilterInactive) {
        const isFilled = [];

        precificationFilterInactive.map(price => {
            const categoryFitlerNotAplicableNoPromotion = price.priceNoPromotion.filter(noPromotion => !noPromotion.notApplicable);
            const categoryFitlerNotAplicablePromotion = price.priceWithPromotion.filter(promotion => !promotion.notApplicable);

            isFilled.push(PrecificationFormService.checkPriceIsFilled(categoryFitlerNotAplicableNoPromotion));
            isFilled.push(PrecificationFormService.checkPriceIsFilled(categoryFitlerNotAplicablePromotion));
        });

        return isFilled;
    },

    setupFormConstruction(doses, vaccinePriccingForm){
        vaccinePriccingForm.hasNumberOfDoses = false;

        if(!doses.numberOfDosesWithPromotion.quantity && !doses.numberOfDosesWithoutPromotion.quantity) {
            vaccinePriccingForm.hasNumberOfDoses = true;
            vaccinePriccingForm.hasDosesNoPromotion = false;
            vaccinePriccingForm.hasDosesPromotion = false;
            vaccinePriccingForm.makeFormPrecification();
        } else if(!doses.numberOfDosesWithPromotion.quantity && doses.numberOfDosesWithoutPromotion.quantity) {
            vaccinePriccingForm.hasDosesNoPromotion = true;
            vaccinePriccingForm.hasDosesPromotion = false;
            vaccinePriccingForm.makeFormPrecification();
        } else if(doses.numberOfDosesWithPromotion.quantity && !doses.numberOfDosesWithoutPromotion.quantity) {
            vaccinePriccingForm.hasDosesNoPromotion = false;
            vaccinePriccingForm.hasDosesPromotion = true;
            vaccinePriccingForm.makeFormPrecification();
        } else {
            vaccinePriccingForm.hasDosesNoPromotion = true;
            vaccinePriccingForm.hasDosesPromotion = true;
            vaccinePriccingForm.makeFormPrecification();
        }
    }
}