var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"minimal-information"},[_c('main',{staticClass:"card p-2"},[_c('section',{staticClass:"formCampanha mt-2"},[_c('validation-observer',{ref:"campanhaRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12"}},[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Nome da Campanha*","label-for":"name-campanha"}},[_c('validation-provider',{attrs:{"name":"nameCampanha","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('X'.repeat(200)),expression:"'X'.repeat(200)"}],attrs:{"id":"name-campanha","state":errors.length > 0 || _vm.form.errors.nameCampanha
                        ? false
                        : null,"autocomplete":"off","placeholder":"Dê o nome da campanha"},model:{value:(_vm.descricaoSelecionada),callback:function ($$v) {_vm.descricaoSelecionada=$$v},expression:"descricaoSelecionada"}}),(errors[0])?_c('small',{staticClass:"text-danger d-block"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.form.errors.nameCampanha)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errors.nameCampanha)+" ")]):_vm._e()]}}])})],1)],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"lg":"6","md":"6","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Doenca","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Doença*","label-for":"doencas","state":errors.length > 0 || _vm.form.errors.doencas ? false : null}},[_c('v-select',{attrs:{"id":"doencas","reduce":function (option) { return option.id_doenca; },"variant":"primary","options":_vm.formDoencas,"label":"nome_tecnico","clearable":false,"placeholder":"Selecione uma doença","disabled":_vm.desabilitar},on:{"input":_vm.selectedDoencas},model:{value:(_vm.form.doencas),callback:function ($$v) {_vm.$set(_vm.form, "doencas", $$v)},expression:"form.doencas"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Nenhuma opção selecionável.")])]),(errors[0])?_c('small',{staticClass:"text-danger d-block"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.form.errors.doencas)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errors.doencas)+" ")]):_vm._e()],1)]}}])})],1),_c('b-col',{attrs:{"lg":"6","md":"6","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Vacina","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Vacina*","label-for":"vaccines","state":errors.length > 0 || _vm.form.errors.vaccines ? false : null}},[_c('v-select',{attrs:{"id":"vaccines","options":_vm.formVacinas,"close-on-select":false,"multiselect":"","multiple":"","selectable":function () { return _vm.form.vacinas.length < 3; },"label":"nome_tecnico","placeholder":"Selecione uma ou mais","disabled":_vm.desabilitarVacina},on:{"input":_vm.selectedVaccines},model:{value:(_vm.form.vacinas),callback:function ($$v) {_vm.$set(_vm.form, "vacinas", $$v)},expression:"form.vacinas"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Nenhuma opção selecionável.")])]),(errors[0])?_c('small',{staticClass:"text-danger d-block"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.form.errors.vacinas)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errors.vacinas)+" ")]):_vm._e()],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"lg":"3","md":"6","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Vacina","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Data de Início*","label-for":"inicio-input"}},[_c('b-input-group',[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],staticClass:"form-control",attrs:{"id":"inicio-input","disabled":_vm.disableFieldData,"placeholder":"00/00/0000","autocomplete":"off"},on:{"input":function($event){return _vm.setInput($event, 'data_inicio')}},model:{value:(_vm.input.data_inicio),callback:function ($$v) {_vm.$set(_vm.input, "data_inicio", $$v)},expression:"input.data_inicio"}}),_c('b-input-group-append',[_c('b-form-datepicker',{staticClass:"form-control d-flex align-items-center",attrs:{"disabled":_vm.disableFieldData,"locale":"pt-BR","button-only":"","right":"","hide-header":true,"label-help":"Selecione a data de início","button-variant":"custom-blue","clearable":""},on:{"input":function($event){return _vm.setPicker($event, 'data_inicio')}},model:{value:(_vm.form.data_inicio),callback:function ($$v) {_vm.$set(_vm.form, "data_inicio", $$v)},expression:"form.data_inicio"}})],1)],1)],1)]}}])})],1),_c('b-col',{attrs:{"lg":"3","md":"6","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Vacina","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Data de Término*","label-for":"termino-input"}},[_c('b-input-group',[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],staticClass:"form-control",attrs:{"id":"termino-input","state":_vm.errorOnDateInicio || _vm.errorOnDateTermino ? false : null,"disabled":_vm.disableFieldData,"placeholder":"00/00/0000","autocomplete":"off"},on:{"input":function($event){return _vm.setInput($event, 'data_termino')}},model:{value:(_vm.input.data_termino),callback:function ($$v) {_vm.$set(_vm.input, "data_termino", $$v)},expression:"input.data_termino"}}),_c('b-input-group-append',[_c('b-form-datepicker',{staticClass:"form-control d-flex align-items-center",attrs:{"disabled":_vm.disableFieldData,"locale":"pt-BR","min":new Date(),"button-only":"","right":"","hide-header":true,"label-help":"Selecione a data de término","button-variant":"custom-blue","clearable":""},on:{"input":function($event){return _vm.setPicker($event, 'data_termino')}},model:{value:(_vm.form.data_termino),callback:function ($$v) {_vm.$set(_vm.form, "data_termino", $$v)},expression:"form.data_termino"}})],1)],1),(_vm.errorOnDateInicio || _vm.errorOnDateTermino)?_c('small',{staticClass:"text-danger"},[_vm._v(" A data da término não pode ser uma data passada. ")]):_vm._e()],1)]}}])})],1)],1)],1)],1),_c('hr',{staticClass:"mt-2"})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }