<template>
  <section class="minimal-information">
    <CustomAlert
      variant="danger"
      message="A quantidade de doses precisa ser menor ou igual ao definido previamente. Altere os campos ou ajuste a quantidade."
      :show="showAlert"
    />
    <b-tabs content-class="mt-0" nav-wrapper-class="no-margin">
      <b-tab
        v-for="(vaccine, index) in selectedVaccinesTabs.vaccines"
        :key="index"
        title-link-class="font-tab"
        title-item-class="custom-tab-style"
      >     
        <template #title>
          <div class="d-flex justify-content-center align-items-center">
            <feather-icon
              v-show="vaccine.error"
              id="erro-dose-distribution"
              icon="AlertTriangleIcon"
              class="text-danger"
              size="18"
            />
            <h4 class="m-0">{{ vaccine.nome_tecnico }}</h4>
          </div>
        </template>
        <main class="bg-white p-2">
            
          <WrapperFromGroupPricingAndDosesDistribution
            :idVaccine="vaccine.id_vacina"
            :vaccinesEdited="selectedVaccinesTabs.vaccines"
            :incentives="selectedIncentives"
            :numberOfDoses="
              selectedVaccinesTabs.numberOfDoses
                ? selectedVaccinesTabs.numberOfDoses[index]
                : null
            "
            @noRegion="handleNoRegion"
            @noUnit="handleNoUnit"
            @errorNumberDoses="handleErroDoses"
            @validDoseDistribution="handleValidDistribution"
          />
          
        </main>
      </b-tab>
    </b-tabs>
  </section>
</template>

<script>
import { BCard, BTabs, BTab, BCardText } from "bootstrap-vue";
import WrapperFromGroupPricingAndDosesDistribution from "./WrapperFromGroupPricingAndDosesDistribution.vue";
import CustomAlert from "@/views/components/custom/timed-alert/CustomAlert.vue";

import { busEvent } from "@/main";

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    BCardText,
    CustomAlert,
    WrapperFromGroupPricingAndDosesDistribution,
  },

  props: {
    selectedVaccines: {
      required: true,
      type: Array | null,
    },
    incentives: {
      required: true,
      type: Object | null,
    },
    numberOfDoses: {
      required: true,
      type: Object | null,
    },
  },

  data() {
    return {
      selectedVaccinesTabs: {
        vaccines: this.$props.selectedVaccines.vaccines,
        numberOfDoses: this.$props.numberOfDoses,
      },
      saveVaccinePricing: [],
      saveDistributionRegion: [],
      saveDistributionUnidade: [],
      distributionSaved: 0,
      precificationSaved: 0,
      selectedIncentives: null,
      hasError: false,
      validVaccines: [],
      showAlert: false,
    };
  },

  mounted() {
    busEvent.$on("hasNoErrorsDistribution", () => {
      this.hasError = false;
    });

    this.eventsPrepareDistribution();
  },

  methods: {
    validateTabVaccine(idVaccine, hasError) {
      this.selectedVaccinesTabs.vaccines.map((vaccine) => {
        if (vaccine.id_vacina == idVaccine) {
          vaccine.error = hasError;
        }
      });
    },

    setupValidVaccines() {
      this.selectedVaccinesTabs.vaccines.map((vaccine) => {
        this.validVaccines[vaccine.id_vacina] = true;
      });
    },

    handleErroDoses(idVaccine) {
      this.validateTabVaccine(idVaccine, true);

      this.hasError = true;

      this.distributionSaved = 0;
      this.precificationSaved = 0;

      this.$emit("errorNumberDoses");
      this.$emit("errorPrecificationDistributionDoses");
    },

    handleValidDistribution(info) {
      this.validVaccines[info.idVaccine] = info.isValid;
      this.validateTabVaccine(info.idVaccine, !info.isValid);

      if (!this.validVaccines.includes(false)) {
        this.showAlert = false;
        this.$emit("validDoseDistribution");
      } else {
        this.showAlert = true;
      }
    },

    handleNoRegion() {
      this.$emit("noRegion");
    },

    handleNoUnit() {
      this.$emit("noUnit");
    },

    handleSaveVaccinePricing(vaccinePricing) {
      this.saveVaccinePricing.push(vaccinePricing);

      if (
        this.selectedVaccinesTabs.vaccines.length ==
        this.saveVaccinePricing.length
      ) {
        busEvent.$emit("vaccinePricingDataCampaign", this.saveVaccinePricing);
        this.saveVaccinePricing = [];
      }
    },

    handleSaveDistributionRegion(distributedRegion) {
      this.saveDistributionRegion.push(distributedRegion);

      if (
        this.selectedVaccinesTabs.vaccines.length ==
        this.saveDistributionRegion.length
      ) {
        busEvent.$emit("doseDistributionRegion", this.saveDistributionRegion);
        this.saveDistributionRegion = [];
      }
    },

    handleSaveDistributionUnidade(distributionUnidade) {
      this.saveDistributionUnidade.push(distributionUnidade);

      if (
        this.selectedVaccinesTabs.vaccines.length ==
        this.saveDistributionUnidade.length
      ) {
        busEvent.$emit("doseDistributionUnidade", this.saveDistributionUnidade);
        this.saveDistributionUnidade = [];
      }
    },

    eventsPrepareDistribution() {
      busEvent.$on("distributionRegionPrepare", (distributedRegion) => {
        this.handleSaveDistributionRegion(distributedRegion);
      });
      busEvent.$on("doseDistributionUnidadePrepare", (distributedUnidade) => {
        this.handleSaveDistributionUnidade(distributedUnidade);
      });
      busEvent.$on("vaccinePricingDataCampaignPrepare", (vaccinePricing) => {
        this.handleSaveVaccinePricing(vaccinePricing);
      });
    },
  },

  watch: {
    selectedVaccines(infosCampagnForm) {
      this.validVaccines = [];
      this.selectedVaccinesTabs.vaccines = infosCampagnForm.vaccines;
      busEvent.$emit("AllVaccinesCadastro", infosCampagnForm.vaccines);
      this.setupValidVaccines();
      this.showAlert = false;
    },

    incentives(values) {
      this.selectedIncentives = values;
    },

    numberOfDoses: {
      handler(newValue) {
        this.selectedVaccinesTabs.numberOfDoses = newValue;
      },
      deep: true,
    },
  },
};
</script>
