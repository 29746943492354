export default {
    adjustLayoutRight(value, index) {
        if(
            (value.length == 3 && index < 2) || 
            (value.length == 2 && index < 1))  
        {
            return true;
        } 
    },

    adjustLayoutRightWithPromotion(value, index) {
        if(
            (value.length == 3 && index <= 2) || 
            (value.length == 2 && index == 1) || 
            (value.length == 1)
        ) {
            return true;
        }
    },

    adjustLayoutLeft(value, index) {
        if(
            (value.length == 3 && index == 0) || 
            (value.length == 2 && index < 2) || 
            (value.length == 1)
        ) {
            return true;
        }
    }
}