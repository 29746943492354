export default {
    fillValuesFormVaccinePricing(vaccinePriccingForm, tipoEmpresa, valueNoPromotion, valueWithPromotion) {
        const dataCategoryNoPromotion = [];
        const dataCategoryWithPromotion = [];

        tipoEmpresa.id_categoria.map((id_categoria, index) => {
            if(vaccinePriccingForm.hasDosesNoPromotion) {
                this.fillCategoryValues(
                    dataCategoryNoPromotion, valueNoPromotion, tipoEmpresa.nome_categoria[index], id_categoria
                );
            }

            if(vaccinePriccingForm.hasIncentivePromotion && vaccinePriccingForm.hasDosesPromotion && !tipoEmpresa.customizado) {
                this.fillCategoryValues(
                    dataCategoryWithPromotion, valueWithPromotion, tipoEmpresa.nome_categoria[index], id_categoria
                );
            }
        });

        return {
            categoryNoPromotion: dataCategoryNoPromotion,
            categoryWithPromotion: dataCategoryWithPromotion,
        };
    },

    fillCategoryValues(cateogory, values, category, id_categoria) {
        let itemIndex = cateogory.push({
            value: '0,00',
            category,
            idCategoria: id_categoria,
            notApplicable: false
        });
        cateogory[itemIndex -1].value = values[itemIndex -1] ? values[itemIndex -1].value : '0,00';
        cateogory[itemIndex -1].notApplicable = values[itemIndex -1] ? values[itemIndex -1].notApplicable : false;
    },

    checkPriceValueIsZero(items){
        let completeItems = [];

        if(items.length != 0) {
            items.map(item => {
                if(item.value == '0,00') {
                    completeItems.push(false);
                }
            });
        }

        return completeItems;
    },

    checkIfHasErrorPrices(precificationFilterInactive, vaccinePriccingForm) {
        let errorPrecification = false;
        let allNotAplicableNoPromotion = 0;
        let allNotAplicablePromotion = 0;

        precificationFilterInactive.map(price => {
            const categoryFitlerNotAplicableNoPromotion = price.priceNoPromotion.filter(noPromotion => !noPromotion.notApplicable);
            const categoryFitlerNotAplicablePromotion = price.priceWithPromotion.filter(promotion => !promotion.notApplicable);

            const hasNoPromotionPriceEmpty = this.checkPriceIsEmpty(categoryFitlerNotAplicableNoPromotion);
            const hasPromotionPriceEmpty = this.checkPriceIsEmpty(categoryFitlerNotAplicablePromotion);

            if(hasNoPromotionPriceEmpty.empty || hasPromotionPriceEmpty.empty) {
                errorPrecification = true;
            }

            allNotAplicableNoPromotion += hasNoPromotionPriceEmpty.allNotAplicable;
            allNotAplicablePromotion += hasPromotionPriceEmpty.allNotAplicable;
        });

        if(
            (allNotAplicableNoPromotion == precificationFilterInactive.length && vaccinePriccingForm.numberOfDoses.numberOfDosesWithoutPromotion.quantity) || 
            (allNotAplicablePromotion == precificationFilterInactive.length && vaccinePriccingForm.numberOfDoses.numberOfDosesWithPromotion.quantity)
        ) {
            errorPrecification = true;
            vaccinePriccingForm.priceEmptyCategory = true;
        } else {
            vaccinePriccingForm.priceEmptyCategory = false;
        }

        return errorPrecification;
    },

    prepareCategories(filteredCategory) {
        let categories = [];

        filteredCategory.map(category => {
            if(category.value != '0,00') {
                categories.push({
                    id_categoria: category.idCategoria,
                    valor_dose: category.value.replace(',', '.')
                });
            }
        });

        return categories;
    },

    fillPricesToSave(aplicableCategory, id_incentivo, id_tipo_preco, precosToSave) {
        if(aplicableCategory.length != 0) {
            const categorieValues = this.prepareCategories(aplicableCategory);

            if(categorieValues.length > 0) {
                precosToSave.push({
                    id_incentivo,
                    id_tipo_preco,
                    categoria: categorieValues,
                });
            }
        }
    },

    checkVaccineHasPrice(vaccinePrice) {
        let hasPrice = false;

        if(vaccinePrice.length > 0 ) {
            hasPrice = vaccinePrice.some(price => {
                return price.value != '0,00' && !price.notApplicable;
            })
        }
        return hasPrice;
    },

    checkPriceIsFilled(categoryPrices) {
        let filled = false;
        
        if(categoryPrices.length != 0) {
            categoryPrices.map(prices => {
                if(prices.value != '0,00') {
                    filled = true;
                }
            });
        }

        return filled;
    },

    checkPriceIsEmpty(categoryPrices) {
        let empty = false;

        let allNotAplicable = 0;
        
        if(categoryPrices.length != 0) {
            categoryPrices.map(prices => {
                if(prices.value == '0,00') {
                    empty = true;
                }
            });
        } else {
            allNotAplicable = 1;
        }

        return {empty, allNotAplicable};
    }
}