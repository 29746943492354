<template>
  <section class="minimal-information">
    <main class="card p-2">
      <section class="formCampanha mt-2">
        <validation-observer ref="campanhaRules">
          <b-form>
            <b-row>
              <b-col
                lg="12"
                md="12"
                sm="12"
              >
                <b-form-group
                  label="Nome da Campanha*"
                  label-for="name-campanha"
                  class="mb-0"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="nameCampanha"
                    rules="required"
                  >
                    <b-form-input
                      id="name-campanha"
                      v-model="descricaoSelecionada"
                      v-mask="'X'.repeat(200)"
                      :state="
                        errors.length > 0 || form.errors.nameCampanha
                          ? false
                          : null
                      "
                      autocomplete="off"
                      placeholder="Dê o nome da campanha"
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger d-block"
                    >
                      Este campo é de preenchimento obrigatório.
                    </small>
                    <small
                      v-if="form.errors.nameCampanha"
                      class="text-danger"
                    >
                      {{ form.errors.nameCampanha }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col
                lg="6"
                md="6"
                sm="12"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Doenca"
                  rules="required"
                >
                  <b-form-group
                    label="Doença*"
                    label-for="doencas"
                    :state="
                      errors.length > 0 || form.errors.doencas ? false : null
                    "
                  >
                    <v-select
                      id="doencas"
                      v-model="form.doencas"
                      :reduce="(option) => option.id_doenca"
                      variant="primary"
                      :options="formDoencas"
                      label="nome_tecnico"
                      :clearable="false"
                      placeholder="Selecione uma doença"
                      :disabled="desabilitar"
                      @input="selectedDoencas"
                    >
                      <span slot="no-options">Nenhuma opção selecionável.</span>
                    </v-select>
                    <small
                      v-if="errors[0]"
                      class="text-danger d-block"
                    >
                      Este campo é de preenchimento obrigatório.
                    </small>
                    <small
                      v-if="form.errors.doencas"
                      class="text-danger"
                    >
                      {{ form.errors.doencas }}
                    </small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                lg="6"
                md="6"
                sm="12"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Vacina"
                  rules="required"
                >
                  <b-form-group
                    label="Vacina*"
                    label-for="vaccines"
                    :state="
                      errors.length > 0 || form.errors.vaccines ? false : null
                    "
                  >
                    <v-select
                      id="vaccines"
                      v-model="form.vacinas"
                      :options="formVacinas"
                      :close-on-select="false"
                      multiselect
                      multiple
                      :selectable="() => form.vacinas.length < 3"
                      label="nome_tecnico"
                      placeholder="Selecione uma ou mais"
                      :disabled="desabilitarVacina"
                      @input="selectedVaccines"
                    >
                      <span slot="no-options">Nenhuma opção selecionável.</span>
                    </v-select>
                    <small
                      v-if="errors[0]"
                      class="text-danger d-block"
                    >
                      Este campo é de preenchimento obrigatório.
                    </small>
                    <small
                      v-if="form.errors.vacinas"
                      class="text-danger"
                    >
                      {{ form.errors.vacinas }}
                    </small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                lg="3"
                md="6"
                sm="12"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Vacina"
                  rules="required"
                >
                  <b-form-group
                    label="Data de Início*"
                    label-for="inicio-input"
                  >
                    <b-input-group>
                      <b-form-input
                        id="inicio-input"
                        v-model="input.data_inicio"
                        v-mask="'##/##/####'"
                        class="form-control"
                        :disabled="disableFieldData"
                        placeholder="00/00/0000"
                        autocomplete="off"
                        @input="setInput($event, 'data_inicio')"
                      />
                      <b-input-group-append>
                        <b-form-datepicker
                          v-model="form.data_inicio"
                          class="form-control d-flex align-items-center"
                          :disabled="disableFieldData"
                          locale="pt-BR"
                          button-only
                          right
                          :hide-header="true"
                          label-help="Selecione a data de início"
                          button-variant="custom-blue"
                          clearable
                          @input="setPicker($event, 'data_inicio')"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                lg="3"
                md="6"
                sm="12"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Vacina"
                  rules="required"
                >
                  <b-form-group
                    label="Data de Término*"
                    label-for="termino-input"
                  >
                    <b-input-group>
                      <b-form-input
                        id="termino-input"
                        v-model="input.data_termino"
                        v-mask="'##/##/####'"
                        class="form-control"
                        :state="errorOnDateInicio || errorOnDateTermino ? false : null"
                        :disabled="disableFieldData"
                        placeholder="00/00/0000"
                        autocomplete="off"
                        @input="setInput($event, 'data_termino')"
                      />
                      <b-input-group-append>
                        <b-form-datepicker
                          v-model="form.data_termino"
                          class="form-control d-flex align-items-center"
                          :disabled="disableFieldData"
                          locale="pt-BR"
                          :min="new Date()"
                          button-only
                          right
                          :hide-header="true"
                          label-help="Selecione a data de término"
                          button-variant="custom-blue"
                          clearable
                          @input="setPicker($event, 'data_termino')"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small
                      v-if="errorOnDateInicio || errorOnDateTermino"
                      class="text-danger"
                    >
                      A data da término não pode ser uma data passada.
                    </small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
        <hr class="mt-2">
      </section>
    </main>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BBadge,
  BAlert,
  BInputGroup,
  BFormDatepicker,
  BInputGroupAppend,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import moment from 'moment'
import {
  CampanhaNacionalGetState,
  CampanhaNacionalGetFormDoencas,
  CampanhaNacionalSetDoencaSelecionada,
  CampanhaNacionalGetDoencaSelecionada,
  CampanhaNacionalSetFormVacinas,
  CampanhaNacionalGetFormVacinas,
  CampanhaNacionalSetVacinasSelecionada,
  CampanhaNacionalGetVacinasSelecionada,
  CampanhaNacionalGetQtdAdesoes,
  CampanhaNacionalSetDescricao,
  CampanhaNacionalGetDescricao,
  CampanhaNacionalSetDataInicioSelecionado,
  CampanhaNacionalGetDataInicio,
  CampanhaNacionalSetDataTerminoSelecionado,
  CampanhaNacionalGetDataTermino,
  CampanhaNacionalResetVacinas,
} from '@/views/custom-pages/campanha-nacional/Helpers/campanhaNacionalHelpers.js'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BBadge,
    BAlert,
    BInputGroup,
    BFormDatepicker,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },

  props: {
    removedVaccina: {
      required: false,
      type: Number,
    },
    vacinas: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      required,
      desabilitar: true,
      disableFieldData: true,
      form: {
        nameCampanha: null,
        doencas: null,
        vacinas: [],
        data_inicio: null,
        data_termino: null,
        errors: {
          nameCampanha: null,
          doencas: null,
          vacinas: null,
        },
      },
      input: {
        data_inicio: null,
        data_termino: null,
      },
      vaccines: [],
      errorOnDateInicio: false,
      errorOnDateTermino: false,
      mensagemCampo:
        'Não é possível realizar esta ação pois já existe um ou mais grupo(s) vinculado a(s) vacina(s)',
    }
  },

  computed: {
    campanhaNacionalState() {
      return CampanhaNacionalGetState()
    },
    formDoencas() {
      return CampanhaNacionalGetFormDoencas()
    },
    formVacinas() {
      return CampanhaNacionalGetFormVacinas()
    },
    doencaSelecionada() {
      return CampanhaNacionalGetDoencaSelecionada()
    },
    vacinaSelecionada() {
      return CampanhaNacionalGetVacinasSelecionada()
    },
    descricaoSelecionada: {
      get: () => CampanhaNacionalGetDescricao(),
      set: value => CampanhaNacionalSetDescricao(value),
    },
    desabilitarVacina() {
      return !!CampanhaNacionalGetQtdAdesoes()
    },
  },

  watch: {
    removedVaccina(id_vacina) {
      this.form.vaccines = this.form.vaccines.filter(
        vaccine => vaccine.id_vacina !== id_vacina,
      )
      this.emitEventSelectedVaccines()
    },
    vacinas(val) {
      this.form.vacinas = val
    },
  },

  mounted() {
    if (CampanhaNacionalGetDoencaSelecionada() != null) {
      this.form.doencas = CampanhaNacionalGetDoencaSelecionada()
    }
    if (CampanhaNacionalGetDataInicio() != null) {
      this.setPicker(CampanhaNacionalGetDataInicio(), 'data_inicio')

      this.disableFieldData = moment(CampanhaNacionalGetDataInicio(), 'YYYY-MM-DD')
        .isBefore(moment().format('YYYY-MM-DD'))
    }
    if (CampanhaNacionalGetDataTermino() != null) {
      this.setPicker(CampanhaNacionalGetDataTermino(), 'data_termino')
    }
    this.form.vacinas = this.$props.vacinas
  },

  methods: {
    validateForm() {},

    selectedDoencas() {
      this.form.errors.doencas = null
      this.form.vacinas = []
      CampanhaNacionalSetDoencaSelecionada(this.form.doencas)
      CampanhaNacionalSetFormVacinas(this.form.doencas)
    },

    selectedVaccines() {
      if (this.form.vacinas.length) {
        CampanhaNacionalSetVacinasSelecionada(this.form.vacinas)
      } else {
        CampanhaNacionalResetVacinas()
      }
    },

    setDataInicio(dataDB) {
      const data = moment(dataDB, 'YYYY-MM-DD')

      this.form.data_inicio = dataDB
      this.input.data_inicio = data.format('DD/MM/YYYY')

      CampanhaNacionalSetDataInicioSelecionado(dataDB)
    },

    setDataTermino(dataDB) {
      const data = moment(dataDB, 'YYYY-MM-DD')

      this.form.data_termino = dataDB
      this.input.data_termino = data.format('DD/MM/YYYY')

      CampanhaNacionalSetDataTerminoSelecionado(dataDB)
    },

    setPicker(data, campo) {
      if (campo == 'data_inicio') {
        this.setDataInicio(data)
        return
      }
      this.setDataTermino(data)
    },

    setInput(data, campo) {
      if (data.length == 10) {
        const dataDB = moment(this.input[campo], 'DD/MM/YYYY').format(
          'YYYY-MM-DD',
        )

        if (campo == 'data_inicio') {
          this.setDataInicio(dataDB)
          return
        }
        this.setDataTermino(dataDB)
      }
    },
  },
}
</script>
