<template>
    <b-modal
        id="modal-error-number-doses"
        no-close-on-backdrop
        no-close-on-esc
        hide-header
        hide-footer
        centered
        v-model="modalShow"
    >
        <div class="d-flex flex-column align-items-center justify-center-center my-2">
            <feather-icon
                id="modal-erro-doses"
                icon="AlertCircleIcon"
                class="text-danger mb-2"
                size="80"
            />
            <h3>{{ title ? title : 'Erro na quantidade de doses' }}</h3>
            <div class="text-center">
                <span>{{ infoTextModal }}</span>
            </div>
            <div class="d-flex mt-2">
                <b-button :disabled="loading" variant="custom-blue" @click="confirmAction" >
                    <span v-if="loading" class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                        {{ action }}
                </b-button>
            </div>
        </div>
    </b-modal>
</template>

<script>
import { BModal, BButton } from 'bootstrap-vue';
export default {
    components: {
        BModal, BButton
    },

    props: {
        modalShow: {
            required: true,
            type: Boolean
        },
        typeModal: {
            required: true,
            type: String
        },
        infoText: {
            type: String
        }
    },

    data() {
        return {
            loading: false,
            modalTypes: [
                {
                    'type': 'rascunho-regiao',
                    'action': 'Ok!',
                    'infoText': `A quantidade de doses precisa ser menor ou igual ao definido previamente. Altere os campos superiores ou ajuste a quantidade entre as regiões.`
                },
                {
                    'type': 'normal-regiao',
                    'action': 'Ok!',
                    'infoText': `A quantidade de doses precisa ser exatamente igual ao definido previamente. Altere os campos superiores ou ajuste a quantidade entre as regiões.`
                },
                {
                    'type': 'error-precification',
                    'action': 'Ok!',
                    'title': 'Erro na precificação de doses',
                    'infoText': `Deve ser informado preços para todas as categorias aplicáveis`
                },
                {
                    'type': 'rascunho-unidade',
                    'action': 'Ok!',
                    'infoText': `A quantidade de doses precisa ser menor ou igual ao definido previamente nas regiões. Altere os campos anteriores ou ajuste a quantidade entre as unidades.`
                },
                {
                    'type': 'normal-unidade',
                    'action': 'Ok!',
                    'infoText': `A quantidade de doses precisa ser exatamente igual ao definido previamente nas regiões. Altere os campos anteriores ou ajuste a quantidade entre as unidades.`
                }
            ],
            type: '',
            title: null,
            action: '',
            infoTextModal: ''
        }
    },

    methods: {
        confirmAction(modalEvent) {
            modalEvent.preventDefault();

            this.loading = true;

            this.$emit('confirmed', modalEvent);
        }
    },

    watch: {
        modalShow() {
            this.loading = false;

            this.modalTypes.map(item => {
                if(item.type == this.$props.typeModal) {
                    this.action = item.action;
                    this.title = item.title ? item.title : null;
                    this.infoTextModal = item.infoText;
                }
            });
        }
    }
}
</script>

