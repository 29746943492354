<template>
  <PrecificationBaseForm :busEvent="busEvent" :formContext="formContext" />
</template>

<script>
import PrecificationHelper from "@/views/custom-pages/Campanhas/Helpers/FormPrecificationHelper";
import PrecificationBaseForm from "@/views/custom-pages/Campanhas/BaseForms/PrecificationBaseForm.vue";
import { busEvent } from "@/main";

export default {
  components: {
    PrecificationBaseForm,
  },

  props: {
    
  },

  data() {
    return {
      busEvent,
      formContext: this,
      loadingCompanyType: true,
      formPrecification: [],
      tiposEMpresaCategorias: [],
      hasIncentivePromotion: null,
      hasDosesPromotion: true,
      hasDosesNoPromotion: true,
      hasNumberOfDoses: false,
      selectedIncentives: null,
      selectedVaccine: null,
      noTypePrice: false,
      showModalError: false,
      quantityTypePrice: 0,
      hasDistributionDoses: false,
    };
  },

  mounted() {
    this.$http.get(this.$api.departamentoTipoEmpresa()).then(({ data }) => {
      this.quantityTypePrice = data.length;
      this.tiposEmpresaCategorias = data;
      this.hasNumberOfDoses = true;
      this.hasDosesNoPromotion = false;
      this.hasDosesPromotion = false;
      this.makeFormPrecification();
    });

    busEvent.$on("saveCampanha", this.prepareDataSavePrecification);
    busEvent.$on("hasDistribution", this.handledistributionPrice);
  },

  beforeDestroy() {
    busEvent.$off("saveCampanha", this.prepareDataSavePrecification);
    busEvent.$off("hasDistribution", this.handledistributionPrice);
  },

  methods: {
    makeFormPrecification() {
      let oldPrices = [];
      if (this.formPrecification) {
        oldPrices = this.formPrecification;
        this.formPrecification = [];
      }

      if (!this.tiposEmpresaCategorias) {
        this.hasNumberOfDoses = false;
        this.loadingCompanyType = false;
        this.noTypePrice = true;
        return;
      }

      PrecificationHelper.makeTiposEmpresaCategoriasPrecification(
        this,
        oldPrices
      );

      this.loadingCompanyType = false;
    },

    prepareDataSavePrecification() {
      let precosToSave = [];

      if (PrecificationHelper.checkIfCanSavePrecification(this, busEvent)) {
        this.emitEventPrepareDataVaccine(precosToSave);
        return;
      }

      precosToSave = PrecificationHelper.preparePrecificationToSave(
        this.formPrecification,
        this.selectedIncentives
      );

      this.emitEventPrepareDataVaccine(precosToSave);
    },

    emitEventPrepareDataVaccine(precosToSave) {
      busEvent.$emit("vaccinePricingDataCampaignPrepare", {
        id_vacina: this.selectedVaccine,
        preco: precosToSave,
      });
    },

    handledistributionPrice(hasDistribution) {
      if (
        this.selectedVaccine == hasDistribution.vaccineId &&
        hasDistribution.hasDistribution
      ) {
        this.hasDistributionDoses = true;
      }
    },
  },

  watch: {
    hasPromotion(value) {
      this.hasIncentivePromotion = value;
    },

    numberOfDoses: {
      handler(newValue) {
        if (!this.tiposEmpresaCategorias) {
          return;
        }

        PrecificationHelper.setupFormConstruction(newValue, this);
        PrecificationHelper.checkIfCanSavePrecification(this, busEvent);
      },
      deep: true,
    },

    idVacccine(idVaccine) {
      this.selectedVaccine = idVaccine;
    },

    incentives(incentives) {
      this.selectedIncentives = incentives;
    },
  },
};
</script>
