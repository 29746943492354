<template>
    <div class="container-summary">
        <div 
            v-for="(prices, index) in precificationData"
            :key="index"
            class="ml-1"
        >
            <p class="custom-font-bold text-dark m-0 line-height-custom">{{ prices.companyType }}</p>

            <div v-if="prices.priceWithPromotion.length > 0 && prices.priceNoPromotion.length > 0">
                <p v-if="prices.activeCompanyType || (!hasNumberDosesAvailable && !prices.priceNoPromotion.length > 0 && !prices.priceWithPromotion.length > 0)">Categoria Inativada</p>
                <div
                    v-else
                    v-for="(priceValues, index) in prices.priceNoPromotion"
                    :key="index"
                >
                    <p v-if="prices.priceNoPromotion.length > 0" class="mb-0 line-height-custom">
                        {{ priceValues.category }}:  
                        <label v-if="!priceValues.notApplicable" class="text-custom-blue">{{ priceValues.value }}</label>
                        <label v-else>Inativado</label>
                    </p>
                    <div v-if="hasIncentivoPromotionAvailable && prices.priceWithPromotion.length > 0" class="d-flex">
                        <label class="point-font ml-1">·</label>
                        <p class="mb-0 line-height-custom">
                            Com Fomento: 
                            <label v-if="prices.priceWithPromotion[index] && !prices.priceWithPromotion[index].notApplicable" class="text-custom-blue">{{ prices.priceWithPromotion[index].value }}</label>
                            <label v-else>Inativado</label>
                        </p>
                    </div>
                </div>
            </div>

            <div v-if="prices.priceWithPromotion.length > 0 && !prices.priceNoPromotion.length > 0">
                <p v-if="prices.activeCompanyType || (!hasNumberDosesAvailable && !prices.priceWithPromotion.length > 0)">Categoria Inativada</p>
                <div
                    v-else
                    v-for="(priceValues, index) in prices.priceWithPromotion"
                    :key="index"
                >
                    <div class="d-flex">
                        <label class="point-font ml-1">·</label>
                        <p class="mb-0 line-height-custom">
                            Com Fomento: 
                            <label v-if="!priceValues.notApplicable" class="text-custom-blue">{{ priceValues.value }}</label>
                            <label v-else>Inativado</label>
                        </p>
                    </div>
                </div>
            </div>

            <div v-if="!prices.priceWithPromotion.length > 0 && prices.priceNoPromotion.length > 0">
                <p v-if="prices.activeCompanyType || (!hasNumberDosesAvailable && !prices.priceNoPromotion.length > 0)">Categoria Inativada</p>
                <div
                    v-else
                    v-for="(priceValues, index) in prices.priceNoPromotion"
                    :key="index"
                >
                    <p class="mb-0 line-height-custom">
                        {{ priceValues.category }}:  
                        <label v-if="!priceValues.notApplicable" class="text-custom-blue">{{ priceValues.value }}</label>
                        <label v-else>Inativado</label>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        formPrecification: {
            required: true,
        },
        hasNumberOfDoses: {
            required: true,
            type: Boolean
        },
        hasIncentivePromotion: {
            required: true,
            type: Boolean
        }
    },

    data() {
        return {
            precificationData: this.$props.formPrecification,
            hasNumberDosesAvailable: this.$props.hasNumberOfDoses,
            hasIncentivoPromotionAvailable: this.$props.hasIncentivePromotion
        }
    },

    watch: {
        formPrecification(precificationNewData) {
            this.precificationData = precificationNewData;
        },

        hasNumberOfDoses(dataDosesAvailableNewData) {
            this.hasNumberDosesAvailable = dataDosesAvailableNewData;
        },

        hasIncentivePromotion(hasIncentivePromotionNewData) {
            this.hasIncentivoPromotionAvailable = hasIncentivePromotionNewData;
        }
    }
}
</script>

<style scoped>
    .container-summary {
        max-height: 430px;
        overflow-y: scroll;
    }
    .custom-font-bold {
        font-weight: 800;
        margin-bottom: 3px !important;
    }

    .line-height-custom {
        line-height: 18px;
    }

    .point-font {
        font-size: 2rem;
        line-height: 12px;
        margin-right: 5px;
    }
</style>