<template>
  <div class="card p-2 shadow-none">
    <section class="formHeader">
      <div>
        <b-button
          class="mr-2 mb-2 mb-md-0"
          variant="custom-blue"
          :disabled="saving"
          @click="saveInfosCampanha()"
        >
          <feather-icon icon="CheckIcon" class="mr-25" />
          <span>Salvar</span>
          <span v-if="saving" class="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>
        </b-button>
        <b-button
          id="cancel-register-campanha"
          variant="outline-danger"
          class="mt-0 mb-2 mb-md-0"
          :disabled="saving"
          @click="openModalConfirmCancel"
        >
          <feather-icon icon="XIcon" class="mr-50" />
          <span class="align-middle">Cancelar</span>
        </b-button>
      </div>
    </section>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";
import {
  CampanhaNacionalSalvarCampanha,
  CampanhaNacionalEditarCampanha,
  CampanhaNacionalGetState,
  CampanhaNacionalSetResponse,
} from "@/views/custom-pages/campanha-nacional/Helpers/campanhaNacionalHelpers.js";
import helpers from "@/helpers";
import { errorMessage, successCheckCircleMessageConfirmAction } from "@/libs/sweetalerts";

export default {
  components: { BButton },
  props: {
    edit: {
      type: Boolean,
      required: false,
    },
    idCampanha: {
      type: Number,
      required: false,
    }
  },
  data() {
    return {
      saving: false
    };
  },

  methods: {
    saveInfosCampanha() {
      this.saving = true;

      if(this.$props.edit){
        CampanhaNacionalEditarCampanha(this.$props.idCampanha);
      }else{
        CampanhaNacionalSalvarCampanha();
      }
    },
    redirecionarLista() {
      this.$router.push({ name: "campanha-list-nacional" });
    },
    redirecionarShow(id_campanha) {
      this.$router.push({
        name: "campaign-show-nacional",
        params: {
          idCampanha: id_campanha,
        },
      });
    },
    openModalConfirmCancel() {
      this.$swal({
            title: 'Tem certeza de que deseja fechar sem salvar as alterações?',
            icon: 'warning',
            iconColor: "red",
            html: '<div>As mudanças não salvas serão perdidas.</div>',
            confirmButtonText: 'Continuar editando',
            showCancelButton: true,
            cancelButtonText: 'Sim, cancelar',
            customClass: {
              confirmButton: "btn btn-custom-blue mr-2",
              cancelButton: 'btn btn-outline-danger'
            },
            buttonsStyling: false
        }).then((result) => {
          if (result.isDismissed) {
            this.redirecionarLista();
          }
      })
    },
    modal(response) {
      if (response.status == null) {
        return;
      }

      let sucesso = response.status == 201;
      let idCampanha = response.idCampanha;
      let title = sucesso ? "Campanha salva!" : "Ocorreu um erro.";
      let messageErros = helpers.uniqueArray(response.message);
      let message = '<div class="text-left">' + messageErros.join("<br>") + "</div>";

      if(sucesso) {
        successCheckCircleMessageConfirmAction(message, title).then(() => {
          this.redirecionarShow(idCampanha);
        })
      } else {
        errorMessage(title, message);
        this.saving = false;
      }

      CampanhaNacionalSetResponse({
        status: null,
        message: [],
      });
    },
  },

  watch: {
    "$store.state.campanhaNacionalState.response.status": {
      immediate: true,
      handler() {
        const response = CampanhaNacionalGetState().response;
        this.modal(response);
      },
    }
  },
};
</script>

