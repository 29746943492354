<template>
  <div>
    <ErrorNumberOfDoses
      :modalShow="showModal"
      typeModal="error-precification"
      @confirmed="closeModal"
    />

    <b-alert variant="warning" :show="formContext.allIsDeactivated">
      <div class="alert-body">
        <span
          >Para continuar a distribuição, informe ao menos um preço das
          categorias aplicadas.</span
        >
      </div>
    </b-alert>

    <b-alert variant="warning" :show="formContext.priceEmptyCategory">
      <div class="alert-body">
        <span
          >É necessário informar ao menos um preço em uma das categorias para
          cada incentivo.</span
        >
      </div>
    </b-alert>

    <CustomAlert
      variant="warning"
      message="O departamento não possui configuração de precificação. Para mais informações entre em contato com suporte. "
      :show="formContext.noTypePrice"
    />

        <div v-if="formContext.loadingCompanyType" class="d-flex justify-content-center mt-4">
            <div class="spinner-border text-custom-blue" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <b-row v-else>
            <b-col 
                xl="8" lg="8" md="12" sm="12"
                class="pr-xl-0 pr-lg-2 pr-md-2 pr-sm-0">
                <b-form>
                    <app-collapse>
                        <app-collapse-item
                            :class="formContext.hasNumberOfDoses ? 'disabled-collapse' : ''"
                            v-for="(price, priceIndex) in formContext.formPrecification"
                            :key="priceIndex"
                            :isVisible="priceIndex == 0 && price.priceNoPromotion.length > 0"
                            :title="price.companyType" 
                            class="mb-2 item-price"
                        >
                            <template slot="header">
                                <h4 class="text-custom-blue mb-0">{{ price.companyType }}</h4>
                                <b-badge class="rounded-pill" v-if="!formContext.allIsDeactivated && price.complete" variant="light-success">
                                    Completo
                                </b-badge>
                                <b-badge class="rounded-pill" v-else variant="light-danger">
                                    Incompleto
                                </b-badge>
                            </template>

                            <section class="pricingForm pt-0 pb-1 px-1 max-width-form">
                                <b-row v-if="price.priceNoPromotion.length > 0">
                                    <b-col 
                                        v-for="(priceInputsNoPromotion, index) in price.priceNoPromotion" 
                                        :key="index"
                                        sm="12"
                                        md="4"
                                        :class="{'pr-0': HelperLayout.adjustLayoutRight(price.priceNoPromotion, index)}"
                                    >
                                        <b-form-group>
                                            <label :for="'price-'+priceInputsNoPromotion.category">{{ priceInputsNoPromotion.category }}</label>
                                            <b-input-group>
                                                <b-input-group-prepend>
                                                    <div 
                                                        :class="{
                                                            'bg-custom-blue d-flex align-items-center custom-append-input': true,
                                                            'disabled-append': priceInputsNoPromotion.notApplicable
                                                        }"
                                                    >
                                                        <b-img :src="Ricon" alt="Icone que representa o real"></b-img>
                                                    </div>
                                                </b-input-group-prepend>
                                                <b-form-input
                                                    v-model="priceInputsNoPromotion.value"
                                                    @input="() => {
                                                        PrecificationHelper.checkIfCanSavePrecification(formContext, busEvent) 
                                                        PrecificationHelper.checkIfPrecificationIsComplete(priceIndex, formContext.formPrecification)
                                                    }"
                                                    @keydown="$event.key === '-' && $event.preventDefault()"
                                                    :class="{
                                                        'border-color-input': true,
                                                        'border-color-input-disabled': priceInputsNoPromotion.notApplicable
                                                    }"
                                                    v-money="moneyMask"
                                                    :id="'price-'+priceInputsNoPromotion.category"
                                                    placeholder="00,00"
                                                    :disabled="priceInputsNoPromotion.notApplicable"
                                                    role="without-promotion"
                                                />
                                            </b-input-group>
                                            <div class="demo-inline-spacing">
                                                <b-form-checkbox 
                                                    v-model="priceInputsNoPromotion.notApplicable" plain 
                                                    @input="PrecificationHelper.checkIfCanSavePrecification(formContext, busEvent)"
                                                    @change="PrecificationHelper.activeCompanyType(price.companyType, formContext.formPrecification)"
                                                    class="m-0 check-margin"
                                                    :name="'not-aplicable-check'+priceInputsNoPromotion.category"
                                                >
                                                    Não se aplica 
                                                </b-form-checkbox>
                                            </div>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row v-if="formContext.hasIncentivePromotion && price.priceWithPromotion.length > 0" class="background-custom-blue p-1">
                                    <h5 class="w-100">Com fomento</h5>
                                    <b-col 
                                        v-for="(priceInputsWithPromotion, index) in price.priceWithPromotion"  
                                        :key="index"
                                        sm="12"
                                        md="4"
                                        :class="{
                                            'pr-0': HelperLayout.adjustLayoutRightWithPromotion(price.priceWithPromotion, index), 
                                            'pl-0': HelperLayout.adjustLayoutLeft(price.priceWithPromotion, index)
                                        }"
                                    >
                                        <b-form-group>
                                            <label :for="'price-'+priceInputsWithPromotion.category">{{ priceInputsWithPromotion.category }}</label>
                                            <b-input-group>
                                                <b-input-group-prepend>
                                                    <div  
                                                        :class="{
                                                            'bg-custom-blue d-flex align-items-center custom-append-input': true,
                                                            'disabled-append': priceInputsWithPromotion.notApplicable
                                                        }"
                                                    >
                                                        <b-img :src="Ricon" alt="Icone que representa o real"></b-img>
                                                    </div>
                                                </b-input-group-prepend>
                                                <b-form-input
                                                    v-model="priceInputsWithPromotion.value"
                                                    @input="() => {
                                                        PrecificationHelper.checkIfCanSavePrecification(formContext, busEvent) 
                                                        PrecificationHelper.checkIfPrecificationIsComplete(priceIndex, formContext.formPrecification)
                                                    }"
                                                    @keydown="$event.key === '-' && $event.preventDefault()"
                                                    :class="{
                                                        'border-color-input': true, 
                                                        'border-color-input-disabled': priceInputsWithPromotion.notApplicable
                                                    }"
                                                    v-money="moneyMask"
                                                    :id="'price-'+priceInputsWithPromotion.category"
                                                    placeholder="00,00"
                                                    :disabled="priceInputsWithPromotion.notApplicable"
                                                    role="with-promotion"
                                                />
                                            </b-input-group>
                                            <div class="demo-inline-spacing">
                                                <b-form-checkbox 
                                                    v-model="priceInputsWithPromotion.notApplicable" plain
                                                    @input="PrecificationHelper.checkIfCanSavePrecification(formContext, busEvent)" 
                                                    @change="PrecificationHelper.activeCompanyType(price.companyType, formContext.formPrecification)"
                                                    class="m-0 check-margin"
                                                    :name="'not-aplicable-check'+priceInputsWithPromotion.category"
                                                >
                                                    Não se aplica 
                                                </b-form-checkbox>
                                            </div>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </section>

                            <section class="buttons">
                                <b-button 
                                    class="mr-2 mt-1"
                                    @click="PrecificationHelper.changeStateCategory(price.companyType, !verifyIfIsApplicable(price), formContext)"
                                    id="active_category" 
                                    variant="outline-secondary"
                                >
                                    {{ verifyIfIsApplicable(price) ? 'Inativar Categoria' : 'Ativar Categoria' }}
                                </b-button>
                                <b-button 
                                    class="mr-2 mt-1" 
                                    @click="PrecificationHelper.cleanFormCategory(price.companyType, formContext.formPrecification)"
                                    id="clean-fields" 
                                    variant="outline-warning"
                                >Limpar Campos</b-button>
                            </section>
                        </app-collapse-item>
                    </app-collapse>
                </b-form>
            </b-col>
            <b-col 
                xl="4" lg="4" md="12" sm="12"
                class="separator pr-xl-0 pr-lg-2 pr-md-2 pt-md-2 pr-sm-0 pt-sm-2">
                <SummaryPricing 
                    :formPrecification="formContext.formPrecification"
                    :hasNumberOfDoses="formContext.hasNumberOfDoses"
                    :hasIncentivePromotion="formContext.hasIncentivePromotion"
                />
            </b-col>
        </b-row>

  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BButton,
  BImg,
  BFormCheckbox,
  BAlert,
  BBadge,
} from "bootstrap-vue";
import Ricon from "@/assets/images/icons/RIcon.svg";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

import ErrorNumberOfDoses from "@/views/components/custom/modals/ModalErrorNumberOfDoses.vue";
import PrecificationHelper from "@/views/custom-pages/Campanhas/Helpers/FormPrecificationHelper";
import HelperLayout from "../Services/AdjustLayoutPricesService";

import SummaryPricing from "@/views/components/custom/summary-pricing/SummaryPricing.vue";
import CustomAlert from "@/views/components/custom/timed-alert/CustomAlert.vue";

export default {
  components: {
    ErrorNumberOfDoses,
    SummaryPricing,
    BRow,
    BCol,
    BForm,
    AppCollapse,
    AppCollapseItem,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BButton,
    BImg,
    BFormCheckbox,
    BAlert,
    BBadge,
    CustomAlert,
  },

  props: ["busEvent", "formContext"],

  data() {
    return {
      Ricon,
      HelperLayout,
      PrecificationHelper,
      moneyMask: {
        decimal: ",",
        thousands: ".",
        precision: 2,
      },
      showModal: this.$props.formContext.showModalError,
      noPrices: false,
    };
  },

  methods: {
    closeModal() {
      this.showModal = false;
    },
    verifyIfIsApplicable(data) {
      let isApplicable = data.priceNoPromotion.every(item => item.notApplicable === false);
      let isApplicableWithPromotion = data.priceWithPromotion.every(item => item.notApplicable === false);

      let notApplicableCount = 0
      if (data.companyType !== "Convênio"){
        data.priceNoPromotion.forEach((item) => item.notApplicable === false ? notApplicableCount++ : notApplicableCount )
        data.priceWithPromotion.forEach((item) => item.notApplicable === false ? notApplicableCount++ : notApplicableCount)
      }

      if ((isApplicable && notApplicableCount === 3) || (!isApplicable && notApplicableCount !== 0)){
          return true
      }

      if ((isApplicableWithPromotion && notApplicableCount === 6) || (!isApplicableWithPromotion && notApplicableCount !== 0)){
          return true
      }

      return isApplicable && isApplicableWithPromotion
    }
  },

  watch: {
    showModalError(show) {
      this.showModal = show;
    },
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables/_variables.scss";

.disabled-collapse {
  pointer-events: none;
  background-color: #ededed;
}

.separator {
  border-left: 1px solid #ebe9f1;
  height: 100%;
}

.background-custom-blue {
  background-color: rgba(39, 114, 192, 0.12);
  border-radius: 6px;
}

.custom-append-input {
  border-radius: 0.357rem;
  z-index: 5;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}

.disabled-append {
  background: $placeholder-color !important;
}

.font-bold {
  font-weight: 800;
  font-size: 2rem;
}

.border-color-input {
  border-color: $custom-blue;
  margin-left: -4px;
}

.border-color-input-disabled {
  border-color: $placeholder-color !important;
}

.check-margin {
  margin-top: 5px !important;
}

.max-width-form {
  max-width: 85%;
}

@media screen and (max-width: 990px) {
  .separator {
    border-left: none;
    padding-left: 0;
    margin-left: 0;
    margin-bottom: 1.5rem;
  }
}
</style>